import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  nl_be: null,
  nl_nl: null,
  fr_be: null,
  fr_fr: null,
  en_gb: null,
  de_de: null
});

export default () => ({
  ...initialCrudState(initialModel)
});
